import "../Sidebar/scss/single-side-bar.module.scss";
import "../ContentWrapper/scss/app.module.scss";
import "./matrix.scss";

import React, { useEffect } from "react";

import { connect } from "react-redux";
import headerStyles from "../Header/scss/desktop.module.scss";
import headerWrapperStyles from "../Header/scss/header-wrapper.module.scss";
import sharedStyles from "../Header/scss/shared.module.scss";

const Matrix = ({ virtualBooths }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    virtualBooths && (
      <section>
        <div
          className={`${headerWrapperStyles.header} ${headerWrapperStyles.preEvent}`}
          role="banner"
        >
          <a href="#content" className="sr-focusable">
            Skip to main content
          </a>
          <div
            className={`${sharedStyles.headerLogo} ${sharedStyles.preEvent}`}
          >
            <div className={headerStyles.headerInterior}>
              <h1>
                <a className={`${sharedStyles.headerLogo}`}>
                  <img
                    src="/images/RSNA-header-logo.png"
                    alt="RSNA 2020"
                    role="presentation"
                    aria-hidden="true"
                  />
                  <div className="sr-only">RSNA 2020</div>
                </a>
              </h1>
              <nav
                className={headerStyles.headerInteriorRight}
                aria-label="Primary"
                role="navigation"
              ></nav>
            </div>
          </div>
        </div>
        <div>
          <table cellPadding="0" cellSpacing="0" className="matrix">
            <tbody>
              <tr>
                <td>
                  <strong>Click to Preview</strong>
                </td>
              </tr>
              {virtualBooths.map((booth) => (
                <tr key={booth.client_id}>
                  <td>
                    <a href={`/exhibitor/${booth.client_id}`} target="_blank">
                      {booth.name}
                    </a>
                  </td>
                </tr>
              ))}
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    )
  );
};
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    virtualBooths: state.global.virtualBooths,
  };
};

export default connect(mapStateToProps)(Matrix);
