import "../ContentWrapper/scss/app.module.scss";

import breadcrumbsStyles from "../scss/breadcrumbs.module.scss";
import exhibitorDocumentStyles from "./scss/exhibitor-documents.module.scss";
import sideBarStyles from "../Sidebar/scss/single-side-bar.module.scss";
import singleExhibitorStyles from "./scss/single-exhibitor.module.scss";
import socialLinkStyles from "../SocialLinks/scss/social-links.module.scss";

//.${singleExhibitorStyles.singleSpeakerContainer} h1, .${singleExhibitorStyles.singleSpeakerContainer} .${singleExhibitorStyles.singleWebsite}, .$//{singleExhibitorStyles.singleSpeakerContainer} .pdf-links .link-title,  .${singleExhibitorStyles.singleSpeakerContainer} a, .representative-wrapper li a {color: $//{booth.colors.subheader_link_color};}
//
//    .${singleExhibitorStyles.scheduleMeetingContainer} .${singleExhibitorStyles.detailsButton} {background-color: ${booth.colors.subheader_link_color}; //border-color: ${booth.colors.subheader_link_color}; }
//
//    .${singleExhibitorStyles.scheduleMeetingContainer} .${singleExhibitorStyles.detailsButton}:hover {background-color: #ffffff; border-color: ${booth.colors.//subheader_link_color} !important; color: ${booth.colors.subheader_link_color} !important; }
//
//    .left-details .details-title, .${singleExhibitorStyles.singleSpeakerContainer} .content-wrapper-with-contact h4,.${singleExhibitorStyles.//singleSpeakerContainer}  .representative-wrapper .representative-header { color: ${booth.colors.subheader_link_color} !important; border-bottom-color: $//{booth.colors.subheader_link_color} !important;}
//
//    .${breadcrumbsStyles.backButton} svg polyline {stroke: ${booth.colors.subheader_link_color} !important;}
//    .${breadcrumbsStyles.breadcrumbsContainer} a, .${breadcrumbsStyles.backButton} a:hover {color: ${booth.colors.subheader_link_color} !important;}
//
//    .${singleExhibitorStyles.singleSpeakerContainer} .${exhibitorDocumentStyles.pdfLinks} svg circle {fill: ${booth.colors.subheader_link_color};}
//
//    .${singleExhibitorStyles.singleSpeakerContainer} .${socialLinkStyles.linkListItem}{ background-color: ${booth.colors.subheader_link_color}; border-color: $//{booth.colors.subheader_link_color};}
const ExhibitorBoothStyles = (props) => {
  const { booth } = props;

  const subheader_link_color = booth.colors.subheader_link_color
    ? booth.colors.subheader_link_color
    : "#00A0DF";

  return `<style
              type="text/css"
              id="exhibitor-theme"
            >
            .single-exhibitor-light-theme .${socialLinkStyles.linkListItem},
.single-exhibitor-dark-theme .${socialLinkStyles.linkListItem} {
  background-color: ${subheader_link_color};
  border: 1px solid ${subheader_link_color};
  color: #ffffff; }
      
.single-exhibitor-dark-theme .${singleExhibitorStyles.content} .${singleExhibitorStyles.singleListDetails} li{
  color: #ffffff; }

.single-exhibitor-light-theme .${singleExhibitorStyles.singleWebsite},
.single-exhibitor-dark-theme .${singleExhibitorStyles.singleWebsite},
.${singleExhibitorStyles.singleListDetails} .representative-wrapper li.exhibitor-contact-phone,
.${singleExhibitorStyles.singleListDetails} .representative-wrapper li.exhibitor-contact-email a {
  color: ${subheader_link_color}; }
.single-exhibitor-light-theme .${singleExhibitorStyles.singleWebsite}.single-exhibitor-contact,
.single-exhibitor-dark-theme .${singleExhibitorStyles.singleWebsite}.single-exhibitor-contact {
  font-weight: 600; }
          
.single-exhibitor-light-theme .${exhibitorDocumentStyles.pdfLinks} svg circle,
.single-exhibitor-dark-theme .${exhibitorDocumentStyles.pdfLinks} svg circle {
  fill: ${subheader_link_color}; }
            
            

.single-exhibitor-light-theme .${exhibitorDocumentStyles.pdfLinks} .${exhibitorDocumentStyles.linkTitle},
.single-exhibitor-dark-theme .${exhibitorDocumentStyles.pdfLinks} .${exhibitorDocumentStyles.linkTitle} {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: ${subheader_link_color}; }

.single-exhibitor-light-theme .${singleExhibitorStyles.content} .${singleExhibitorStyles.singleListDetails} li,
.single-exhibitor-dark-theme .${singleExhibitorStyles.content} .${singleExhibitorStyles.singleListDetails} li {
  font-weight: normal;
  font-size: 16px; }

.single-exhibitor-dark-theme .${singleExhibitorStyles.content} {
  background-color: #171516; }

.single-exhibitor-dark-theme .${breadcrumbsStyles.backButton},
.single-exhibitor-light-theme .${breadcrumbsStyles.backButton},
.single-exhibitor-dark-theme .${breadcrumbsStyles.backButton}:hover,
.single-exhibitor-light-theme .${breadcrumbsStyles.backButton}:hover {
  color: ${subheader_link_color}; }
.single-exhibitor-dark-theme .${breadcrumbsStyles.backButton} svg polyline {
  stroke: ${subheader_link_color}; }

.single-exhibitor-dark-theme .${sideBarStyles.sideBar} {
  background-color: #2e2c2d !important; }

.${sideBarStyles.sideBar} .details-title {
  color: ${subheader_link_color};
  border-bottom-color: ${subheader_link_color}; }

.single-exhibitor-dark-theme .${sideBarStyles.sideBar} li,
.single-exhibitor-dark-theme .${sideBarStyles.sideBar} .exhibitor-product-categories li {
  color: #ffffff; }

.single-exhibitor-light-theme .${sideBarStyles.sideBar} li,
.single-exhibitor-light-theme .${sideBarStyles.sideBar} .exhibitor-product-categories li {
  color: #2E2C2D; }

.single-exhibitor-dark-theme .${singleExhibitorStyles.content} p {
  color: #ffffff; }

  .single-exhibitor-light-theme .${singleExhibitorStyles.content} p {
  color: #2E2C2D; }

.single-exhibitor-dark-theme .${singleExhibitorStyles.content} h1 {
  color: #ffffff !important; }

.single-exhibitor-light-theme .${singleExhibitorStyles.content} h1 {
  color: #000000 !important; }

.${singleExhibitorStyles.scheduleMeetingContainer} .${singleExhibitorStyles.detailsButton} {
  background-color: ${subheader_link_color};
  border-color: ${subheader_link_color}; }
.${singleExhibitorStyles.scheduleMeetingContainer} .${singleExhibitorStyles.detailsButton}:hover {
  color: ${subheader_link_color}; }

.content-wrapper-with-contact h4,
.representative-wrapper .representative-header {
  color: ${subheader_link_color};
  border-bottom: 1px solid ${subheader_link_color}; }
            </style>`;
};

export default ExhibitorBoothStyles;
